import * as Icon from 'react-feather';
import { useErrorCheck } from '../../../hooks/useErrorCheck';
import { Loader } from 'react-feather';
import { useFieldsUploadMutation } from '../../../hooks/useJianDetail';

export const ErrorMessage = () => {
  const { status, isLoading, queryError, errorCount, warningCount } = useErrorCheck();
  const totalErrorCount = errorCount && errorCount?.dispatch + errorCount?.patient;
  const totalWarningCount = warningCount && warningCount?.dispatch + warningCount?.patient;
  const mutation = useFieldsUploadMutation();

  if (status === 'PENDING' || isLoading) {
    return (
      <div className="flex gap-2 items-center">
        <Loader className="animate-spin text-darkblue_50" size={18} />
        <div className="text-base text-darkblue_50">エラーチェックを実行中...</div>
      </div>
    );
  }

  if (queryError) {
    return (
      <div className="flex items-center gap-3 text-error text-base font-bold">
        <div className="flex items-center gap-1">
          <Icon.AlertTriangle size={16} className="min-w-max" />
          エラーチェックに失敗しました
        </div>
        <button
          onClick={() => mutation.mutate()}
          className="flex items-center gap-2 bg-white hover:bg-white/70 text-darkblue_100 rounded border-none px-2 py-1"
        >
          <Icon.RefreshCw size={16} className="min-w-max" />
          再実行
        </button>
      </div>
    );
  } else if (!totalErrorCount && !totalWarningCount) {
    return <div className="text-darkblue_70 text-base">エラーは検出されませんでした</div>;
  } else {
    return (
      <div className="flex flex-col gap-2">
        {!!totalErrorCount && (
          <div className="flex items-center gap-1 text-error text-base">
            <Icon.AlertCircle size={16} className="min-w-max" />
            {errorCount.dispatch + errorCount.patient}件のエラーが検出
          </div>
        )}
        {!!totalWarningCount && (
          <div className="flex items-center gap-1 text-warning text-base">
            <Icon.AlertTriangle size={16} className="min-w-max" />
            <p>{warningCount.dispatch + warningCount.patient}件の警告が検出</p>
          </div>
        )}
      </div>
    );
  }
};
