import React, { Suspense, useCallback, useState } from 'react';
import { CheckSquare, Plus, Square, X } from 'react-feather';
import { Dialog } from '@headlessui/react';

import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { TableFooter } from './TableFooter';
import { useJianList } from '../../../hooks/useJianList';
import { createDisplayValue } from '../../../components/JianListTable/JianListItem/JianListItemCell';

export const Table: React.VFC = () => {
  const [selectedAppJianAsPatientInfo, setSelectedAppJianAsPatientInfo] = useState('');
  return (
    <>
      <div className="w-full border-t-4 border-b-4 border-darkblue_100 text-darkblue_100">
        <div className="overflow-auto max-h-112">
          <table className="w-full relative">
            <TableHeader />
            <TableBody
              onSelectedAppJianAsPatientInfo={(appJianId) =>
                setSelectedAppJianAsPatientInfo(appJianId)
              }
            />
          </table>
        </div>
        <TableFooter />
      </div>

      {/* 「傷病者として追加」する時に表示する事案選択ダイアログ */}
      <TargetJianSelectDialog
        open={selectedAppJianAsPatientInfo !== ''}
        onClose={() => setSelectedAppJianAsPatientInfo('')}
        onSubmit={(jianId) => {
          console.log(
            `アプリ事案(${selectedAppJianAsPatientInfo})を傷病者として事案(${jianId})に追加`
          );
        }}
      />
    </>
  );
};

// 「傷病者として追加」する時に表示する事案選択ダイアログ
const TargetJianSelectDialog: React.VFC<{
  open: boolean;
  onClose: () => void;
  onSubmit: (jianId: string) => void;
}> = ({ open, onClose, onSubmit }) => {
  const [selectedJianId, setSelectedJianId] = useState('');
  const handleClose = useCallback(() => {
    setSelectedJianId('');
    onClose();
  }, [onClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
    >
      <Dialog.Panel className="relative z-50 w-192 h-fit bg-white border-2 border-darkblue_100 rounded-md px-6 py-4 flex flex-col gap-3">
        <Dialog.Title className="text-xl font-bold text-darkblue_100">
          傷病者として追加
        </Dialog.Title>
        <Dialog.Description className="text-base text-darkblue_70">
          傷病者として追加する対象の事案を選択してください
        </Dialog.Description>
        <div className="w-full">
          <Suspense fallback={<div className="h-96">Loading...</div>}>
            <JianTable
              selectedJianId={selectedJianId}
              onSelectJian={(jianId) => setSelectedJianId(jianId)}
            />
          </Suspense>
        </div>
        <button
          onClick={() => {
            onSubmit(selectedJianId);
            handleClose();
          }}
          disabled={selectedJianId === ''}
          className="flex items-center gap-1.5 w-fit bg-darkblue_100 disabled:bg-darkblue_40 text-white rounded p-2"
        >
          <Plus size={16} />
          傷病者として追加
        </button>
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 flex items-center gap-1.5 w-fit text-darkblue_100 rounded px-2 py-1 border border-darkblue_100"
        >
          <X size={16} />
          閉じる
        </button>
      </Dialog.Panel>
    </Dialog>
  );
};

// 単一の事案を選択するための表
const JianTable: React.VFC<{ selectedJianId: string; onSelectJian: (jianId: string) => void }> = ({
  selectedJianId,
  onSelectJian,
}) => {
  const { jianList, page, isLastPage, incrementPage, decrementPage } = useJianList();
  const summaryFields = jianList?.items.at(0)?.summaryFields ?? [];
  return (
    <div className="border-t-2 border-b-2 border-darkblue_100">
      <div className="overflow-scroll h-96 w-full">
        <table className="table whitespace-nowrap">
          <thead className="text-base text-darkblue_100">
            <tr>
              <th className="w-5 sticky top-0 before:w-full before:h-full before:absolute before:top-0 before:left-0 before:bg-white before:border-b-2 before:border-darkblue_100 before:-z-10">
                {/* CheckBox */}
              </th>
              {summaryFields.map((field) => (
                <th
                  key={field.fieldId}
                  className="text-left py-2 sticky top-0 before:w-full before:h-full before:absolute before:top-0 before:left-0 before:bg-white before:border-b-2 before:border-darkblue_100 before:-z-10"
                >
                  <span className="mx-4">{field.fieldName}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm text-darkblue_100">
            {jianList?.items.map((jian) => (
              <tr
                key={jian.jianId}
                className={`border-b border-darkblue_100 ${
                  jian.jianId === selectedJianId ? 'bg-darkblue_10' : 'bg-white'
                }`}
                onClick={() =>
                  jian.jianId === selectedJianId ? onSelectJian('') : onSelectJian(jian.jianId)
                }
              >
                <td className="w-5 p-2">
                  {jian.jianId === selectedJianId ? (
                    <CheckSquare size={16} />
                  ) : (
                    <Square size={16} />
                  )}
                </td>
                {jian.summaryFields.map((field) => {
                  return (
                    <td key={field.fieldId} className="text-left px-4 py-2">
                      {field === undefined
                        ? undefined
                        : createDisplayValue(field.fieldType, field.value)}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="px-3 py-1 border-t-2 border-darkblue_100 text-darkblue_100">
        <div className="flex gap-2 justify-between">
          <div className="text-sm font-bold">
            {page * 10 + 1}件目から{!isLastPage ? `${(page + 1) * 10}件目` : ''}を表示中
          </div>
          <div className="flex gap-6">
            {page > 0 && (
              <button className="text-sm font-bold" onClick={() => decrementPage()}>
                前の10件
              </button>
            )}
            {!isLastPage && (
              <button className="text-sm font-bold" onClick={() => incrementPage()}>
                次の10件
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
