import { useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { useMe } from '../useMe';
import { getAuthToken } from '../../utils/getAuthToken';
import { sleep } from '../../utils/sleep';

// -----------------------------------------------------------------------------
// Util Functions
// -----------------------------------------------------------------------------
const API_URL = process.env.REACT_APP_REST_API_ENDPOINT;

const getBaseUrl = (): string => {
  if (API_URL === undefined) {
    throw new Error('REACT_APP_REST_API_ENDPOINT is not defined');
  }
  return API_URL;
};

// -----------------------------------------------------------------------------
// CRUD Operation Functions
// -----------------------------------------------------------------------------

const postForRequestId = async (fireDepartmentId: string, jianIds: string[]): Promise<string> => {
  const baseUrl = getBaseUrl();
  const token = await getAuthToken();

  const response = await fetch(`${baseUrl}/oa-csv/request`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      fire_department_id: fireDepartmentId,
      target_jian_ids: jianIds,
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to get request id (responce is not ok)');
  }

  const body = await response.json();
  if (typeof body.request_id !== 'string' || body.request_id === '') {
    throw new Error(
      'Failed to get request id (responce body do not have `request_id` property as string)'
    );
  }

  return body.request_id;
};

const getDownloadUrl = async (
  fireDepartmentId: string,
  requestId: string,
  retry: number = 10,
  interval: number = 1000
): Promise<string> => {
  for (let i = 0; i < retry; i++) {
    await sleep(interval);

    const baseUrl = getBaseUrl();
    const token = await getAuthToken();

    const response = await fetch(`${baseUrl}/oa-csv/${fireDepartmentId}/${requestId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to get download url (responce is not ok)');
    }

    const result = await response.json();
    if (
      result.status === 'generated' &&
      typeof result.presigned_url === 'string' &&
      result.presigned_url !== ''
    ) {
      return result.presigned_url;
    }
  }

  throw new Error('Failed to get download url (timeout)');
};

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------
export const useOACsvDownload = () => {
  const me = useMe();
  return useCallback(
    async (jianIds: string[]) => {
      const fireDepartmentId = me?.fireDepartmentId;

      if (fireDepartmentId == null) {
        toast.error('認証情報が取得できませんでした。ログインし直してください。');
        console.error('Failed to get fireDepartmentId');
        return;
      }

      const toastId = toast.loading('ダウンロード中...');
      try {
        const requestId = await postForRequestId(fireDepartmentId, jianIds);
        const downloadUrl = await getDownloadUrl(fireDepartmentId, requestId);
        toast.success('ダウンロードが完了しました', { id: toastId });
        window.location.href = downloadUrl;
      } catch (e) {
        toast.error('ダウンロードに失敗しました', { id: toastId });
        console.error(e);
      }
    },
    [me]
  );
};
