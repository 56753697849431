import { useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { useMe } from '../useMe';
import { getAuthToken } from '../../utils/getAuthToken';
import { sleep } from '../../utils/sleep';

// -----------------------------------------------------------------------------
// Util Functions
// -----------------------------------------------------------------------------
const API_URL = process.env.REACT_APP_REST_API_ENDPOINT;

const getBaseUrl = (): string => {
  if (API_URL === undefined) {
    throw new Error('REACT_APP_REST_API_ENDPOINT is not defined');
  }
  return `${API_URL}/selective-csv/csv`;
};

// -----------------------------------------------------------------------------
// CRUD Operation Functions
// -----------------------------------------------------------------------------

const postForRequestId = async (
  fireDepartmentId: string,
  jianIds: string[],
  fieldIds: string[]
): Promise<string> => {
  const baseUrl = getBaseUrl();
  const token = await getAuthToken();

  const response = await fetch(`${baseUrl}/${fireDepartmentId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      jianIds: jianIds,
      targetFieldIds: fieldIds,
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to get request id (responce is not ok)');
  }

  const body = await response.json();
  if (typeof body.requestId !== 'string' || body.requestId === '') {
    throw new Error(
      'Failed to get request id (responce body do not have `requestId` property as string)'
    );
  }

  return body.requestId;
};

const getDownloadUrl = async (
  fireDepartmentId: string,
  requestId: string,
  retry: number = 10,
  interval: number = 1000
): Promise<string> => {
  for (let i = 0; i < retry; i++) {
    await sleep(interval);

    const baseUrl = getBaseUrl();
    const token = await getAuthToken();

    const response = await fetch(`${baseUrl}/${fireDepartmentId}/${requestId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to get download url (responce is not ok)');
    }

    const result = await response.json();
    if (result.status === 'COMPLETED' && typeof result.url === 'string' && result.url !== '') {
      return result.url;
    }
  }

  throw new Error('Failed to get download url (timeout)');
};

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------
export const useCsvSelectedDownload = () => {
  const me = useMe();
  return useCallback(
    async (jianIds: string[], fieldIds: string[]) => {
      const fireDepartmentId = me?.fireDepartmentId;

      if (fireDepartmentId == null) {
        toast.error('認証情報が取得できませんでした。ログインし直してください。');
        console.error('Failed to get fireDepartmentId');
        return;
      }

      const toastId = toast.loading('ダウンロード中...');
      try {
        const requestId = await postForRequestId(fireDepartmentId, jianIds, fieldIds);
        const downloadUrl = await getDownloadUrl(fireDepartmentId, requestId);
        toast.success('ダウンロードが完了しました', { id: toastId });
        window.location.href = downloadUrl;
      } catch (e) {
        toast.error('ダウンロードに失敗しました', { id: toastId });
        console.error(e);
      }
    },
    [me]
  );
};
