import React, { Suspense, useState } from 'react';

import { TeamSelect } from './TeamSelect/TeamSelect';
import { NameOrCodeInput } from './NameOrCodeInput/NameOrCodeInput';
import { MemberTable } from './MemberTable/MemberTable';
import { useMembers } from '../../hooks/useMembers';
import { useTeams } from '../../hooks/useTeams';
import { components } from '../../schema/member-management';

type Props = {
  initTeamId: string;
  initNameOrCode: string;
  onSelectMember: (
    member: Pick<
      components['schemas']['Member'],
      'memberCode' | 'memberName' | 'licenses' | 'emtClass'
    >
  ) => void;
};

export const MemberSearch: React.VFC<Props> = (props) => {
  return (
    <div className="flex flex-col gap-2 p-3 border-2 border-darkblue_100 bg-white w-144 h-96 rounded">
      <h2 className="font-bold text-xl text-darkblue_100">隊員検索</h2>
      <Suspense fallback={<div>Data Loading...</div>}>
        <MemberSearchContent {...props} />
      </Suspense>
    </div>
  );
};

const MemberSearchContent: React.VFC<Props> = ({ initTeamId, initNameOrCode, onSelectMember }) => {
  const [teamId, setTeamId] = useState(initTeamId);
  const [nameOrCode, setNameOrCode] = useState(initNameOrCode);

  const teams = useTeams();
  const members = useMembers();

  return (
    <>
      <div className="flex gap-2">
        <TeamSelect teams={teams} value={teamId} onChange={(value) => setTeamId(value)} />
        <NameOrCodeInput value={nameOrCode} onChange={(value) => setNameOrCode(value)} />
      </div>
      <div className="w-full h-full overflow-scroll">
        <MemberTable
          members={members
            .filter((member) => teamId === 'ALL' || member.teamId === teamId)
            .filter(
              (member) =>
                member.memberName.includes(nameOrCode) || member.memberCode.includes(nameOrCode)
            )
            .map((member) => ({
              memberId: member.memberId,
              memberName: member.memberName,
              teamName: member.teamName,
              memberCode: member.memberCode,
              emtClass: member.emtClass,
              licenses: member.licenses.map((l) => ({
                licenseId: l.licenseId,
                licenseName: l.licenseName,
              })),
            }))}
          onSelectMember={onSelectMember}
        />
      </div>
    </>
  );
};
