import React from 'react';
import { Popover } from '@headlessui/react';
import * as Icon from 'react-feather';

import { useSetFieldValue } from '../../hooks/useJianDetail';
import { MemberSearch } from '../../components/MemberSearch';
import { components } from '../../schema/member-management';

const stringifyEmtClass = (emtClass: components['schemas']['EmtClass']) => {
  switch (emtClass) {
    case 'PARAMEDIC':
      return '救急救命士';
    case 'ASSOCIATE_AMBULANCE_MEMBER':
      return '准救急隊員';
    case 'OTHER_MEMBER':
      return 'その他隊員';
    default:
      return '';
  }
};

type Props = {
  fieldIdForAutoComplete: {
    memberCode?: string;
    memberName?: string;
    licenseStatus?: string;
    emtClass?: string;
  };
};

export const MemberSearchButton: React.VFC<Props> = ({ fieldIdForAutoComplete }) => {
  const setMemberCode = useSetFieldValue(fieldIdForAutoComplete.memberCode ?? '');
  const setMemberName = useSetFieldValue(fieldIdForAutoComplete.memberName ?? '');
  const setLicenseStatus = useSetFieldValue(fieldIdForAutoComplete.licenseStatus ?? '');
  const setEmtClass = useSetFieldValue(fieldIdForAutoComplete.emtClass ?? '');

  return (
    <Popover className="relative">
      <Popover.Button className="ml-1 p-1 rounded bg-gray-400">
        <Icon.Search size={20} className="text-white" />
      </Popover.Button>
      <Popover.Panel className="absolute z-10 -right-12">
        {({ close }) => (
          <MemberSearch
            initTeamId="ALL"
            initNameOrCode=""
            onSelectMember={(member) => {
              if (fieldIdForAutoComplete.memberCode) setMemberCode(member.memberCode);
              if (fieldIdForAutoComplete.memberName) setMemberName(member.memberName);
              if (fieldIdForAutoComplete.licenseStatus)
                setLicenseStatus(member.licenses.map((l) => l.licenseName));
              if (fieldIdForAutoComplete.emtClass) setEmtClass(stringifyEmtClass(member.emtClass));
              close();
            }}
          />
        )}
      </Popover.Panel>
    </Popover>
  );
};
