import { Suspense, useState } from 'react';

import { components } from '../../schema/hospital-management';
import { LabeledCheckbox } from '../LabeledCheckbox';
import { useHospitalList, useHospitalSearchParams } from '../../hooks/useHospital';
import { HospitalTable } from './HospitalTable/HospitalTable';

type Props = {
  onSelectHospital: (hospital: components['schemas']['Hospital']) => void;
};

export const HospitalSearch: React.VFC<Props> = (props) => {
  return (
    <div className="flex flex-col gap-2 p-3 border-2 border-darkblue_100 bg-white w-160 h-fit rounded">
      <h2 className="font-bold text-xl text-darkblue_100">病院検索</h2>
      <Suspense fallback={<div className="h-96">Data Loading...</div>}>
        <HospitalSearchContent {...props} />
      </Suspense>
    </div>
  );
};

const HospitalSearchContent: React.VFC<Props> = ({ onSelectHospital }) => {
  const { hospitalSearchParams, setHospitalSearchParams } = useHospitalSearchParams();
  const [searchCondition, setSearchCondition] = useState(hospitalSearchParams);
  const { hospitalList, page, isLastPage, incrementPage, decrementPage } = useHospitalList();

  return (
    <div className="space-y-2">
      <form className="space-y-2">
        <div className="flex gap-3">
          <InputText
            label="医療機関名"
            value={searchCondition.hospitalName ?? ''}
            onChange={(value) => setSearchCondition({ ...searchCondition, hospitalName: value })}
          />
          <InputText
            label="国コード"
            value={searchCondition.organizationCode ?? ''}
            onChange={(value) =>
              setSearchCondition({ ...searchCondition, organizationCode: value })
            }
          />
          <InputText
            label="医療機関コード"
            value={searchCondition.ownCode ?? ''}
            onChange={(value) => setSearchCondition({ ...searchCondition, ownCode: value })}
          />
        </div>
        <div className="flex gap-3">
          <button
            type="button"
            className="px-2 py-1 rounded bg-darkblue_70 text-white"
            onClick={() => setHospitalSearchParams(searchCondition)}
          >
            検索
          </button>
          <LabeledCheckbox
            label="お気に入りのみ表示"
            checked={searchCondition.favoriteOnly ?? false}
            fontsize="base"
            onChange={(value) => setSearchCondition({ ...searchCondition, favoriteOnly: value })}
          />
        </div>
      </form>
      <div className="w-full h-60 overflow-scroll">
        <HospitalTable hospitals={hospitalList} onSelectHospital={onSelectHospital} />
      </div>
      <div className="flex items-center">
        {page > 0 && (
          <button
            type="button"
            className="text-darkblue_100 font-xs font-bold"
            onClick={() => decrementPage()}
          >
            前の10件へ
          </button>
        )}
        <div className="grow">{/* Spacer */}</div>
        {!isLastPage && (
          <button
            type="button"
            className="text-darkblue_100 font-xs font-bold"
            onClick={() => incrementPage()}
          >
            次の10件へ
          </button>
        )}
      </div>
    </div>
  );
};

export const InputText: React.VFC<{
  label: string;
  value: string;
  onChange: (value: string) => void;
}> = ({ label, value, onChange }) => {
  return (
    <label className="w-full flex flex-col gap-1">
      <span className="text-sm text-darkblue_100 font-bold">{label}</span>
      <input
        className="text-sm text-darkblue_100 border border-darkblue_100 rounded px-2 py-1.5"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </label>
  );
};
