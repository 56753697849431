import { VFC } from 'react';
import { JapaneseDateInput } from '../../../../components/JapanenseDateInput';

type InputDateProps = {
  presicion: 'day' | 'minute' | 'second';
  value: string | null;
  onNewValueSet: (newValue: string | null) => void;
  disabled?: boolean;
};

export const InputDate: VFC<InputDateProps> = ({ presicion, value, onNewValueSet, disabled }) => {
  return (
    <div className="py-1 px-2 flex flex-row items-center">
      <JapaneseDateInput
        defaultDate={value !== null && !isNaN(Date.parse(value)) ? new Date(value) : undefined}
        presicion={presicion}
        onChange={(date) => {
          if (date === '') {
            onNewValueSet(null);
            return;
          }
          const UTCdatetime = date.toISOString();
          onNewValueSet(UTCdatetime.substring(0, 19) + 'Z');
        }}
        disabled={disabled}
        className={`font-bold ${
          disabled ? 'text-gray-500' : 'text-gray-900'
        } w-full px-3 py-2 text-left rounded bg-transparent border-transparent`}
      />
    </div>
  );
};
