import classNames from 'classnames';
import { VFC } from 'react';
import { ErrorBadges } from './ErrorBadges';

type ActionPanelButtonProps = {
  type: 'light' | 'dark' | 'danger';
  onClick: () => void;
  label: string;
  icon: JSX.Element;
  isDisabled?: boolean;
  isFull?: boolean;
  isSelected?: boolean;
  error?: number;
  warning?: number;
};

export const ActionPanelButton: VFC<ActionPanelButtonProps> = ({
  type,
  onClick,
  label,
  icon,
  isDisabled,
  isFull,
  isSelected,
  error,
  warning,
}) => {
  const className = classNames(
    'shadow-md rounded outline outline-2 flex flex-row justify-center gap-x-4 px-4 py-2',
    { 'hover:bg-darkblue_40': !isDisabled },
    { 'bg-darkblue_70 text-white outline-darkblue_100': !isDisabled && isSelected },
    {
      'bg-disabled-light text-disabled-dark shadow-disabled-main cursor-default ': isDisabled,
    },
    {
      'bg-white text-darkblue_100 shadow-darkblue_40':
        type === 'light' && !isDisabled && !isSelected,
    },
    {
      'bg-darkblue_100 text-white shadow-darkblue_40 outline-darkblue_100':
        type === 'dark' && !isDisabled && !isSelected,
    },
    {
      'bg-white text-danger-dark shadow-danger-main hover:bg-danger-light':
        type === 'danger' && !isDisabled && !isSelected,
    },
    { 'w-full': isFull }
  );

  return (
    <>
      <button className={className} onClick={!isDisabled ? onClick : undefined}>
        <div className="self-center">{icon}</div>
        <div className="self-center">
          <p className="text-l font-bold">{label}</p>
        </div>
        <ErrorBadges error={error} warning={warning} />
      </button>
    </>
  );
};
