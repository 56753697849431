// アプリ事案インポートに関するロジックをまとめたHooks

import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { useRefreshJianList } from './useJianList';
import { datacrudApiClient as client } from '../utils/apiClient';
import type { components } from '../schema/data-crud';
import { useMe } from './useMe';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { useSelectedYear } from './useYears';

// -----------------------------------------------------------------------------
// CRUD Operation Functions
// -----------------------------------------------------------------------------
const getAppJians = (
  teamId: string | undefined,
  limit: number | undefined,
  nextToken: string | undefined
) =>
  client.GET('/app-jians', {
    params: {
      query: {
        teamId,
        limit,
        nextToken,
      },
    },
  });

const importAppJian = (nserMobileJianId: string, year: string | undefined) =>
  client.POST('/app-jians/{nserMobileDataId}/import', {
    params: { path: { nserMobileDataId: nserMobileJianId } },
    body: { year },
  });

const importAppJianAsPatient = (nserMobileJianId: string, targetJianId: string) =>
  client.POST('/app-jians/{nserMobileDataId}/import', {
    params: { path: { nserMobileDataId: nserMobileJianId } },
    body: { jianId: targetJianId },
  });

// -----------------------------------------------------------------------------
// Recoil States
// -----------------------------------------------------------------------------
const currentPageState = atom({
  key: 'appJiansCurrentPageState',
  default: 0,
});

const limitState = atom({
  key: 'appJiansLimitState',
  default: 10,
});

const ImportedAppJianIdsState = atom<string[]>({
  key: 'importedAppJiansState',
  default: [],
});

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------
export const useAppJians = () => {
  const [page, setPage] = useRecoilState(currentPageState);
  const { myTeamOnly } = useMyTeamOnly();
  const teamId = useMe()?.teamId;
  const limit = useRecoilValue(limitState);

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<
    components['schemas']['AppJianListResponse']
  >({
    queryKey: ['appJians', { myTeamOnly }],
    queryFn: async ({ pageParam = undefined }) => {
      const { data, error } = await getAppJians(myTeamOnly ? teamId : undefined, limit, pageParam);

      if (error) {
        throw new Error(`Failed to GET /app-jians \n message: ${error.message}`);
      }
      return data;
    },
    getNextPageParam: (lastPage) => lastPage.nextToken,
  });

  const isLatestPage = data?.pages.length === page + 1;
  const isLastPage = isLatestPage && !hasNextPage;

  const incrementPage = useCallback(() => {
    if (isLastPage) return;
    if (isLatestPage) fetchNextPage();
    setPage(page + 1);
  }, [setPage, fetchNextPage, page, isLastPage, isLatestPage]);

  const decrementPage = useCallback(() => {
    setPage(page > 0 ? page - 1 : 0);
  }, [setPage, page]);

  return {
    appJians: data?.pages.at(page)?.items ?? [],
    page,
    isLastPage,
    incrementPage,
    decrementPage,
  };
};

export const useMyTeamOnly = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const myTeamOnly = searchParams.get('myTeamOnly') === 'true';

  const setAppJianSearchParams = useCallback(
    (params: { myTeamOnly: boolean }) => {
      const newParams = new URLSearchParams();
      if (params.myTeamOnly) newParams.set('myTeamOnly', 'true');
      setSearchParams(newParams);
    },
    [setSearchParams]
  );

  const toggleMyTeamOnly = useCallback(() => {
    setAppJianSearchParams({ myTeamOnly: !myTeamOnly });
  }, [myTeamOnly, setAppJianSearchParams]);

  return { myTeamOnly, toggleMyTeamOnly };
};

export const useImportAppJian = () => {
  const year = useSelectedYear();
  const setImportedAppJianIds = useSetRecoilState(ImportedAppJianIdsState);
  const refreshJianList = useRefreshJianList();
  const refreshAppJians = useRefreshAppJians();

  return useCallback(
    async (nserMobileJianId: string, awarenessDatetime: string) => {
      const awarenessDateYear = new Date(awarenessDatetime).getFullYear();

      if (year !== String(awarenessDateYear)) {
        window.alert('インポートする事案の覚知時刻と現在選択されている年が一致しません');
        return;
      }

      const toastId = toast.loading('事案をインポート中...');

      try {
        const { data, error } = await importAppJian(nserMobileJianId, year);

        if (error) {
          throw new Error(
            `Failed to POST /app-jians/{nserMobileDataId}/import \n message: ${error.message}`
          );
        }
        if (data.nserMobileDataId !== nserMobileJianId) {
          throw new Error('Imported AppJian ID does not match the requested ID');
        }

        toast.success('事案をインポートしました', { id: toastId });
        setImportedAppJianIds((ids) => [...ids, nserMobileJianId]);
        refreshJianList();
        refreshAppJians();
      } catch (e) {
        console.error(e);
        toast.error('インポートに失敗しました', { id: toastId });
      }
    },
    [setImportedAppJianIds, refreshJianList, refreshAppJians, year]
  );
};

export const useImportAppJianAsPatient = () => {
  const setImportedAppJianIds = useSetRecoilState(ImportedAppJianIdsState);
  const refreshJianList = useRefreshJianList();

  return useCallback(
    async (nserMobileJianId: string, targetJianId: string) => {
      const toastId = toast.loading('事案を傷病者情報としてインポート中...');

      try {
        const { data, error } = await importAppJianAsPatient(nserMobileJianId, targetJianId);

        if (error) {
          throw new Error(
            `Failed to POST /app-jians/{nserMobileDataId}/import \n message: ${error.message}`
          );
        }
        if (data.nserMobileDataId !== nserMobileJianId) {
          throw new Error('Imported AppJian ID does not match the requested ID');
        }

        toast.success('事案を傷病者情報としてインポートしました', { id: toastId });
        setImportedAppJianIds((ids) => [...ids, nserMobileJianId]);
        refreshJianList();
      } catch (e) {
        console.error(e);
        toast.error('インポートに失敗しました', { id: toastId });
      }
    },
    [setImportedAppJianIds, refreshJianList]
  );
};

export const useRefreshAppJians = () => {
  const queryClient = useQueryClient();
  const setPage = useSetRecoilState(currentPageState);

  return useCallback(() => {
    queryClient.resetQueries(['appJians']);
    setPage(0);
  }, [setPage, queryClient]);
};
