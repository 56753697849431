import React from 'react';
import { Popover } from '@headlessui/react';
import * as Icon from 'react-feather';

import { components } from '../../schema/hospital-management';
import { useSetFieldValue } from '../../hooks/useJianDetail';
import { HospitalSearch } from '../../components/HospitalSearch/HospitalSearch';

type Props = {
  fieldIdForAutoComplete: {
    code?: string;
    hospitalName?: string;
    hospitalEstablishmentType?: string; // 設立別
    hospitalEmergencyType?: string; // 救急告示
    hospitalEmbodimentType?: string; // 実施形態
    jurisdiction?: string; // 管内・外
  };
};

export const HospitalSearchButton: React.VFC<Props> = ({ fieldIdForAutoComplete }) => {
  const setHospitalCode = useSetFieldValue(fieldIdForAutoComplete.code ?? '');
  const setHospitalName = useSetFieldValue(fieldIdForAutoComplete.hospitalName ?? '');
  const setHospitalEstablishmentType = useSetFieldValue(
    fieldIdForAutoComplete.hospitalEstablishmentType ?? ''
  );
  const setHospitalEmergencyType = useSetFieldValue(
    fieldIdForAutoComplete.hospitalEmergencyType ?? ''
  );
  const setHospitalEmbodimentType = useSetFieldValue(
    fieldIdForAutoComplete.hospitalEmbodimentType ?? ''
  );
  const setJurisdiction = useSetFieldValue(fieldIdForAutoComplete.jurisdiction ?? '');

  return (
    <Popover className="relative">
      <Popover.Button className="ml-1 p-1 rounded bg-gray-400">
        <Icon.Search size={20} className="text-white" />
      </Popover.Button>
      <Popover.Panel className="absolute z-10 -right-12">
        {({ close }) => (
          <HospitalSearch
            onSelectHospital={(hospital) => {
              if (fieldIdForAutoComplete.hospitalName) setHospitalName(hospital.hospitalName);
              if (fieldIdForAutoComplete.code) setHospitalCode(hospital.ownCode);
              if (fieldIdForAutoComplete.hospitalEstablishmentType)
                setHospitalEstablishmentType(hospital.hospitalEstablishmentType);
              if (fieldIdForAutoComplete.hospitalEmergencyType)
                setHospitalEmergencyType(stringfyEmergencyType(hospital.hospitalEmergencyType));
              if (fieldIdForAutoComplete.hospitalEmbodimentType)
                setHospitalEmbodimentType(stringfyEmbodimentType(hospital.hospitalEmbodimentType));
              if (fieldIdForAutoComplete.jurisdiction)
                setJurisdiction(stringfyJurisdiction(hospital.jurisdiction));
              close();
            }}
          />
        )}
      </Popover.Panel>
    </Popover>
  );
};

const stringfyEmergencyType = (emergencyType: components['schemas']['EmergencyType']) => {
  switch (emergencyType) {
    case 'EMERGENCY':
      return '1:救急告示';
    default:
      return '';
  }
};

const stringfyEmbodimentType = (embodimentType: components['schemas']['EmbodimentType']) => {
  switch (embodimentType) {
    case 'INITIAL':
      return '初期';
    case 'SECONDARY':
      return '二次';
    case 'TERTIARY':
      return '三次';
    default:
      return '';
  }
};

const stringfyJurisdiction = (jurisdiction: components['schemas']['Jurisdiction']) => {
  switch (jurisdiction) {
    case 'INSIDE':
      return '1:管内';
    case 'OUTSIDE':
      return '2:管外';
    default:
      return '';
  }
};
