import { VFC } from 'react';
import { Popover } from '@headlessui/react';

import { useJapaneseDateInput } from './hooks/useJapaneseDateInput';
import { convertYearToJapaneseYear, eraList } from './hooks/utils';
import { Input, Select } from './components';
import { Calender } from './Calender';

type Props = {
  onChange: (date: Date | '') => void;
  presicion: 'day' | 'minute' | 'second';
  defaultDate?: Date;
  className?: string;
  disabled?: boolean;
};

export const JapaneseDateInput: VFC<Props> = ({
  onChange,
  presicion,
  defaultDate,
  className,
  disabled,
}) => {
  const {
    inputs,
    selectedDate,
    displayedDateString,
    calenderType,
    calenderCursor,
    errorMessage,
    onChangeValue,
    onToggleCalenderType,
    setCalenderCursor,
    setCurrentDate,
  } = useJapaneseDateInput(presicion, defaultDate, onChange);

  return (
    <Popover className={'relative w-full'}>
      <Popover.Button className={className} disabled={disabled}>
        {displayedDateString}
      </Popover.Button>

      <Popover.Panel className="absolute z-10 space-y-3 bg-white shadow rounded p-2 w-112">
        <div className="flex gap-2 items-center justify-between pb-1 border-b border-darkblue_20">
          <button
            onClick={() => setCurrentDate()}
            className="text-sm text-darkblue_70 rounded hover:bg-darkblue_10 px-2 py-1"
          >
            {presicion === 'day' ? '今日の日付' : '現在時刻'}
          </button>
          {/* 和暦・西暦切り替えボタン */}
          <button
            onClick={onToggleCalenderType}
            className="text-sm text-darkblue_70 rounded hover:bg-darkblue_20 px-2 py-1"
          >
            {calenderType === 'Japanese' ? '西暦で入力' : '和暦で入力'}
          </button>
        </div>

        <div className="px-2 flex gap-3">
          {/* 入力項目 */}
          <div className="space-y-3">
            <div className="flex gap-2">
              <YearInput
                calenderType={calenderType}
                era={inputs.era}
                onChangeEra={(era) => onChangeValue('era', era)}
                year={inputs.year}
                onChangeYear={(year) => onChangeValue('year', year)}
              />
            </div>
            <div className="flex gap-2">
              <Input
                label="月"
                type="text"
                value={inputs.month}
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  onChangeValue('month', e.target.value);
                }}
              />
              <Input
                label="日"
                type="text"
                value={inputs.day}
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  onChangeValue('day', e.target.value);
                }}
              />
            </div>
            {(presicion === 'minute' || presicion === 'second') && (
              <div className="flex gap-2">
                <Input
                  label="時"
                  type="text"
                  value={inputs.hour}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    onChangeValue('hour', e.target.value);
                  }}
                />
                <Input
                  label="分"
                  type="text"
                  value={inputs.minute}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    onChangeValue('minute', e.target.value);
                  }}
                />
              </div>
            )}
            {presicion === 'second' && (
              <div className="flex gap-2">
                <Input
                  label="秒"
                  type="text"
                  value={inputs.second}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    onChangeValue('second', e.target.value);
                  }}
                />
              </div>
            )}
          </div>

          {/* カレンダー */}
          <Calender
            selectedDate={selectedDate}
            calenderCursor={calenderCursor}
            calenderType={calenderType}
            setCalenderCursor={(cursor) => setCalenderCursor(cursor)}
            onSelectDate={(date) => {
              if (calenderType === 'Japanese') {
                const yearValidateion = convertYearToJapaneseYear(String(date.getFullYear()));
                if (!yearValidateion.ok) return;
                onChangeValue('era', yearValidateion.era);
                onChangeValue('year', yearValidateion.year);
              } else {
                onChangeValue('year', String(date.getFullYear()));
              }
              onChangeValue('month', String(date.getMonth() + 1));
              onChangeValue('day', String(date.getDate()));
            }}
          />
        </div>

        {/* エラーメッセージ */}
        <div className="text-sm text-red-500">{errorMessage}</div>
      </Popover.Panel>
    </Popover>
  );
};

const YearInput: VFC<{
  calenderType: 'Japanese' | 'Western';
  era: string;
  onChangeEra: (era: string) => void;
  year: string;
  onChangeYear: (year: string) => void;
}> = ({ calenderType, era, onChangeEra, year, onChangeYear }) => {
  return calenderType === 'Japanese' ? (
    <>
      <Select
        value={era}
        onChange={(e) => {
          onChangeEra(e.target.value);
        }}
      >
        {eraList.map((era) => (
          <option key={era.name} value={era.name}>
            {era.name}
          </option>
        ))}
      </Select>
      <Input
        label="年"
        type="text"
        value={year}
        onFocus={(e) => e.target.select()}
        onChange={(e) => {
          onChangeYear(e.target.value);
        }}
      />
    </>
  ) : (
    <Input
      label="年"
      type="text"
      value={year}
      onFocus={(e) => e.target.select()}
      onChange={(e) => {
        onChangeYear(e.target.value);
      }}
      className="w-20"
    />
  );
};
