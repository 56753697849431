import { useCallback, useState, useMemo } from 'react';

import { formatJapaneseDateTimeString } from '../../../utils/dateUtils';
import {
  Inputs,
  validateInputsAndConvertToDate,
  convertJapaneseYearToYear,
  convertYearToJapaneseYear,
} from './utils';

export const useJapaneseDateInput = (
  presicion: 'day' | 'minute' | 'second',
  defaultDate: Date | undefined,
  onChange: (date: Date | '') => void
) => {
  const initialInputs = useMemo<Inputs>(() => {
    if (defaultDate !== undefined) {
      const year = defaultDate.getFullYear();
      const japaneseYear = convertYearToJapaneseYear(String(year));
      if (japaneseYear.ok) {
        return {
          era: japaneseYear.era,
          year: japaneseYear.year,
          month: String(defaultDate.getMonth() + 1),
          day: String(defaultDate.getDate()),
          hour: String(defaultDate.getHours()),
          minute: String(defaultDate.getMinutes()),
          second: String(defaultDate.getSeconds()),
        };
      }
    }
    return {
      era: '令和',
      year: '',
      month: '',
      day: '',
      hour: '0',
      minute: '0',
      second: '0',
    };
  }, [defaultDate]);

  const [inputs, setInputs] = useState<Inputs>(initialInputs);
  const [calenderType, setCalenderType] = useState<'Japanese' | 'Western'>('Japanese');
  const [calenderCursor, setCalenderCursor] = useState(0);

  const dateValidation = validateInputsAndConvertToDate(calenderType, inputs);
  const selectedDate = dateValidation.ok ? dateValidation.date : undefined;
  const displayedDateString = dateValidation.ok
    ? formatJapaneseDateTimeString(dateValidation.date, presicion)
    : '---';
  const errorMessage = dateValidation.ok ? '' : dateValidation.message;

  // 入力値が変更されたときの処理
  const onChangeInputs = useCallback(
    (calenderType: 'Japanese' | 'Western', inputs: Inputs) => {
      const dateValidation = validateInputsAndConvertToDate(calenderType, inputs);
      if (dateValidation.ok) {
        onChange(dateValidation.date);
      } else {
        onChange('');
      }
      setCalenderCursor(0);
    },
    [onChange]
  );

  // 入力値の中の一つが変更されたときの処理
  const onChangeValue = useCallback(
    (key: keyof Inputs, value: string) => {
      setInputs((prev) => {
        const next = { ...prev, [key]: value };
        onChangeInputs(calenderType, next);
        return next;
      });
    },
    [calenderType, onChangeInputs]
  );

  // 和暦・西暦を切り替え
  const onToggleCalenderType = useCallback(() => {
    setCalenderType((prevCalenderType) => {
      if (prevCalenderType === 'Western') {
        setInputs((prev) => {
          const yearValidation = convertYearToJapaneseYear(prev.year);
          const next: Inputs = yearValidation.ok
            ? { ...prev, era: yearValidation.era, year: yearValidation.year }
            : { ...prev, era: '令和', year: '' };
          onChangeInputs('Japanese', next);
          return next;
        });
        return 'Japanese';
      }

      setInputs((prev) => {
        const yearValidation = convertJapaneseYearToYear(prev.era, prev.year);
        const next: Inputs = yearValidation.ok
          ? { ...prev, year: yearValidation.year }
          : { ...prev, year: '' };
        onChangeInputs('Western', next);
        return next;
      });
      return 'Western';
    });
  }, [onChangeInputs]);

  // 今日の日付または現在時刻を設定
  const setCurrentDate = useCallback(() => {
    const now = new Date();
    const year = String(now.getFullYear());
    const month = String(now.getMonth() + 1);
    const day = String(now.getDate());
    const hour = presicion !== 'day' ? String(now.getHours()) : '0';
    const minute = presicion !== 'day' ? String(now.getMinutes()) : '0';
    const second = presicion === 'second' ? String(now.getSeconds()) : '0';

    setInputs((prev) => {
      if (calenderType === 'Japanese') {
        const yearValidation = convertYearToJapaneseYear(year);
        if (!yearValidation.ok) {
          console.error('Error: current date could not be converted to Japanese date');
          return prev;
        }
        const next: Inputs = {
          ...prev,
          era: yearValidation.era,
          year: yearValidation.year,
          month,
          day,
          hour,
          minute,
          second,
        };
        onChangeInputs(calenderType, next);
        return next;
      }

      const next: Inputs = {
        ...prev,
        year,
        month,
        day,
        hour,
        minute,
        second,
      };
      onChangeInputs(calenderType, next);
      return next;
    });
  }, [presicion, calenderType, onChangeInputs]);

  return {
    inputs,
    selectedDate,
    displayedDateString,
    calenderType,
    calenderCursor,
    errorMessage,
    onChangeValue,
    onToggleCalenderType,
    setCalenderCursor,
    setCurrentDate,
  };
};
