import { SaveButton } from './SaveButton';
import { PatientInfo } from './PatientInfo';
import { useParams } from 'react-router-dom';

export const SubActionPanel = () => {
  const { infoType } = useParams<{ infoType: string }>();

  return (
    <div className="container mx-auto my-2 flex">
      {infoType === 'patient-info' && <PatientInfo />}
      <div className="basis-1/6 ml-auto">
        {/* right block */}
        <SaveButton />
      </div>
    </div>
  );
};
